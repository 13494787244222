

<template>
	<div class="tcontainer" ref="container">
		<Toast />
		<div style="padding: 10px 3px" class="clearfix">
			<div style="float: right; padding-top: 8px; padding-right: 12px;">
				<InputSwitch @change="togglePresent" v-model="isPresent" />
			</div>
			<div style="float: right; padding-top: 6px;">
				<span style="font-weight: bold; font-size: 20px;">Present: &nbsp; </span>
			</div>
			<div v-if="isPresent">
				
				<div v-if="false" style="float: left; font-size: 20px; font-weight: bold;line-height: 46px; margin-right: 8px;">
					Reports
				</div>
				
				<router-link to="/radiologist/reports/pending">
					<button style="margin: 0 5px;"
						:class="{ 'btn-secondary': page != 'pending', 'btn-primary': page == 'pending' }"
						class="btn btn-lg">Pending
						<!-- ({{ radiologist?.total_accepted_reports }}/{{radiologist?.accept_report_limit}}) -->
					</button>
				</router-link>
				<router-link to="/radiologist/reports/completed">
					<button style="margin: 0 5px;"
						:class="{ 'btn-secondary': page != 'completed', 'btn-primary': page == 'completed' }"
						class="btn btn-lg btn-primary">Completed</button>
				</router-link>
				<router-link to="/radiologist/reports/review">
					<button style="margin: 0 5px;"
						:class="{ 'btn-secondary': page != 'review', 'btn-primary': page == 'review' }"
						class="btn btn-lg btn-primary">Review ({{ radiologist?.total_review_reports }})</button>
				</router-link>

			</div>
		</div>

		<div v-if="isPresent" class="clearfix ttitle">

			<button @click="loadLazyData" class="pull-right btn btn-info" title="Reload listing"><i
					class="fa fa-refresh"></i></button>
			<div class="pull-right" style="margin-top: 10px; margin-right: 15px">Last Update: {{ format(refreshTime,
					"hh:mma")
			}}</div>
			<!-- <button @click="showAddEdit" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button> -->
			<div class="title">Manage Xray Reports</div>
		</div>
		<DataTable v-if="isPresent" stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true"
			columnResizeMode="fit" :paginator="true" :rows="100" v-model:filters="filters" ref="dt"
			:totalRecords="totalRecords" :rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading"
			@page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="row"
			responsiveLayout="scroll">


			<Column field="report_id" header="Id" filterField="report_id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<span v-if="data.urgent == 'y'"
							style="border: 1px solid gray; border-radius: 6px; padding: 4px;background: #f00; font-weight: bold; color: #fff;">
							{{ data['report_id'] }}
						</span>
						<span v-else style="border: 1px solid gray; border-radius: 6px; padding: 4px; font-weight: bold; ">{{
								data['report_id']
						}}</span>
						<span v-if="data.urgent == 'y'" class="text-danger ms-1" style="font-weight: bold">Urgent</span>
					</div>
				</template>
			</Column>
			<Column field="center_code" header="Center Code" filterField="center_code" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Center Code" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['center_code'] }}
					</div>
				</template>
			</Column>

			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						{{ data['patient_name'] }} ({{ data['patient_age'] }})
					</div>
				</template>
			</Column>

			<Column field="patient_gender" header="Gender" filterField="patient_gender" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Gender</option>

							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<div style="text-align: center;">
							<span v-if="data['patient_gender'] == 'male'" style="color: blue; font-size: 25px"><i
									class="fa fa-male"></i></span>
							<span v-if="data['patient_gender'] == 'female'" style="color: #ff859a;  font-size: 25px"><i
									class="fa fa-female"></i></span>
						</div>
					</div>
				</template>
			</Column>

			<Column field="test_name" header="Test Name" filterField="test_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Test Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': true ? 'bold' : 'normal' }">
						{{ data['test_name'] }}
					</div>
				</template>
			</Column>

			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<!-- {{data['created_at']}} -->
						<div style="width: 65px; margin: auto;">
							{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
						</div>
					</div>
				</template>
			</Column>
			<Column field="reported_at" header="Reported At" filterField="reported_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Reported At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<div class="tdata" v-if="data['reported_at']" style="width: 65px; margin: auto;">
							{{ format(data['reported_at'], "DD/MM/YY h:mmA") }}
						</div>
					</div>
				</template>
			</Column>

			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Status</option>

							<option value="pending">Pending</option>
							<option value="reported">Reported</option>
							<option value="printed">Printed</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }" style="padding-top: 8px;">
						<sup v-if="data.accepted && data['report'] && data['status'] == 'pending'"
							class="text-success pull-right">draft</sup>
						<span v-if="data['status'] == 'pending'" class="form-control p-0 text-center"
							style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">{{ data['status']
							}}</span>
						<span v-else-if="data['status'] == 'review'" class="form-control p-0 text-center"
							style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">{{ data['status']
							}}</span>
						<span v-else-if="data['status'] == 'reported'" class="form-control p-0 text-center bg-warning"
							style=" color: #fff; padding: 5px; border-radius: 5px;">{{ data['status'] }}</span>
						<span v-else class="form-control p-0 text-center bg-success"
							style="color: #fff; padding: 5px; border-radius: 5px;">{{ data['status'] }}</span>
					</div>
				</template>
			</Column>

			<Column
				header="Tools &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;">
				<template #body="{ data }">
					<div class="tdata options">
						<button @click="showNotesBox(data)" class="btn btn-sm btn-outline-primary"
							:style="{ color: data.notes ? 'green' : '' }">
							<i class="fa fa-sticky-note"></i>
						</button>
						<a v-if="data.images?.length && data.assign_doc_id != '0'" class="btn btn-sm btn-outline-info"
							:href="apiOrigin + '/image-viewer/' + data.report_id"><i class="fa fa-image"></i></a>
						<button v-if="data.images?.length && data.assign_doc_id == '0'" @click="showImageViewer(data)"
							class="btn btn-sm btn-outline-secondary"><i class="fa fa-image"></i></button>
						<router-link v-if="data.assign_doc_id != '0' && (data.status == 'pending' || data.status == 'review')"
							:to="'/radiologist/report/' + data.report_id">
							<button class="btn btn-sm  btn-outline-success ms-1"><i class="fa fa-edit"></i></button>
						</router-link>
						<button v-if="(data.status == 'review' || data.review_done == 'y') && (data.review_notes || data.reviewAudioUrl)" @click="showReviewNotesBox(data)"
							class="btn btn-sm btn-outline-secondary">
							<i class="fa fa-eye"></i>
						</button>
						<button v-if="data.status == 'reported'" title="Print" type="button" class="btn btn-sm btn-outline-success" @click="printReport(data)">
							<i class="fa fa-print"></i>
						</button>
						<!-- <router-link v-if="data.status == 'reported' || data.status == 'printed'"
							:to="'/radiologist/print-xray-report/' + data.report_id">
							<button title="Print" type="button" class="btn btn-sm btn-outline-success ms-1">
								<i class="fa fa-print"></i>
							</button>
						</router-link> -->
					</div>
				</template>
			</Column>


			<Column v-if="false" header="Action">
				<template #body="{ data }">
					<div class="tdata options">
						<div v-if="data.status == 'pending' && data.assign_type != 'specific'">
							<InputSwitch @change="acceptReport(data)" v-model="data['accepted']" />
							<div :style="{ color: data.timer < '00:02:00' ? 'red' : 'inherit' }"><b>{{ data.timer }}</b></div>
						</div>
					</div>
				</template>
			</Column>


		</DataTable>

		<div v-if="!isPresent" style="padding: 180px 0; text-align: center;">
			<h3>You are absent</h3>
		</div>

		<div class="modal fade" ref="notesModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Notes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<textarea v-model="notes" rows="6" class="form-control"></textarea>
					</div>
					<div class="modal-footer">
						<button type="button" @click="saveNotes" class="btn btn-primary">Save</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="imageViewerModal" tabindex="-1">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Images</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<div style="width: 200px; float: left;">
							<div style="margin-bottom: 10px;" v-for="image in allImages" :key="image">
								<img @click="showImage = image" style="max-width: 100%; cursor: pointer;"
									:src="apiOrigin + '/images/radiology/' + image" alt="">
							</div>
						</div>
						<div style="margin-left: 210px;">
							<div>
								<div style="padding: 10px;" class="pull-right"
									v-if="editRow.status == 'pending' && editRow.assign_type != 'specific'">
									<InputSwitch @change="acceptReport(editRow); imageViewerModal.hide();"
										v-model="editRow['accepted']" />
									<div :style="{ color: editRow.timer < '00:05:00' ? 'red' : 'inherit' }"><b>{{ editRow.timer
									}}</b>
									</div>
								</div>
								<div style="padding: 10px;" class="pull-right">
									<button
										v-if="editRow.status == 'pending' && editRow.assign_doc_id == '0' && editRow.assign_type != 'specific' && !editRow['accepted']"
										@click="deletePivotReport(editRow)" class="btn btn-sm btn-outline-danger"><i
											class="fa fa-trash"></i></button>
								</div>
							</div>
							<img v-if="showImage" style="display: block; margin: auto; max-width: 100%"
								:src="apiOrigin + '/images/radiology/' + showImage" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="reviewNotesModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Review Notes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div style="padding: 20px;">
						{{ editRow?.review_notes }}

						<div v-if="editRow.reviewAudioUrl" style="padding-top: 10px;">
							<audio :src="editRow.reviewAudioUrl" controls style="display: block; width: 100%;"></audio>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import Toast from 'primevue/toast';
import InputSwitch from 'primevue/inputswitch';


export default {
	components: {
		Toast,
		InputSwitch
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			editRow: {},
			saving: false,
			notesModal: null,
			reviewNotesModal: null,
			imageViewerModal: null,
			lazyParams: {},
			allImages: [],
			showImage: "",
			radiologist: null,
			notes: '',
			refreshTime: new Date(),
			timerWorking: false,
			page: 'pending',
			isPresent: true,
			filters: {
				'report_id': { value: '', matchMode: 'like' },
				'center_id': { value: '', matchMode: 'like' },
				'center_code': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'patient_age': { value: '', matchMode: 'like' },
				'patient_gender': { value: '', matchMode: '=' },
				'ref_doctor_name': { value: '', matchMode: 'like' },
				'test_id': { value: '', matchMode: 'like' },
				'test_name': { value: '', matchMode: 'like' },
				'test_date': { value: '', matchMode: 'like' },
				'history': { value: '', matchMode: 'like' },
				'reg_no': { value: '', matchMode: 'like' },
				'mobile_no': { value: '', matchMode: 'like' },
				'assign_doc_id': { value: '', matchMode: 'like' },
				'assign_doc_ids': { value: '', matchMode: 'like' },
				'images': { value: '', matchMode: 'like' },
				'report': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
				'urgent': { value: '', matchMode: 'like' },
				'review_notes': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'reported_at': { value: '', matchMode: 'like' },
			},
		}
	},
	async mounted() {

		this.notesModal = new Modal(this.$refs.notesModal, { backdrop: 'static', keyboard: false });
		this.reviewNotesModal = new Modal(this.$refs.reviewNotesModal, { backdrop: 'static', keyboard: false });
		this.imageViewerModal = new Modal(this.$refs.imageViewerModal)

		this.get('radiologist/get-is-present').then(res => {
			this.isPresent = res.isPresent;
		})

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();


		setTimeout(()=>{
			soc.on('xrayReport', d => {
				this.loadLazyData();
				notiAudio.play();
			})
			

		},0)
	},
	unmounted() {
		soc.off('xrayReport');
	},
	methods: {
		printReport(row){
			let data =  btoa(row.center_id+','+row.report_id).replaceAll(/=/ig, "");

			this.$router.push({
				path: "/print-xray-report",
				query: {
					data
				}
			})
		},
		update() {
			this.loadLazyData();
		},
		showNotesBox(data) {
			this.notes = data.notes;
			this.editRow = data;
			this.notesModal.show();
		},
		showImageViewer(data) {
			if (!data.images) return;
			this.editRow = data;
			this.allImages = data.images.split(",");
			this.showImage = this.allImages[0];
			this.imageViewerModal.show();
		},
		showReviewNotesBox(data) {
			this.editRow = data;
			this.reviewNotesModal.show();
		},
		saveNotes() {
			this.post("radiologist/save-notes", {
				notes: this.notes,
				report_id: this.editRow.report_id
			}).then(() => {
				this.notesModal.hide();
				this.loadLazyData();
			})
		},
		async resetHasDirectReport() {
			await this.get('radiologist/reset-has-direct-report');
			this.radiologist = await this.get("radiologist/load-radiologist");
		},
		async acceptReport(data) {
			let accept = await this.post("radiologist/toggle-accept-report", { report_id: data.report_id });
			if (!accept.success){
				if(accept.limitCross){
					this.$toast.add({ severity: 'warn', summary: 'Accept Limit Reached', detail: 'You have reached your accept limit. Please publish accepted reports first in order to accept new reports.', life: 4000 });
				}else{
					this.$toast.add({ severity: 'warn', summary: 'Accepted By Another Doctor', detail: 'This report has already been accepted by another doctor, please try another report.', life: 4000 });
				}
			}
			this.radiologist = await this.get("radiologist/load-radiologist");
			this.loadLazyData();
		},
		async loadLazyData() {
			this.loading = true;
			this.refreshTime = new Date();
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			if (!params.rows) return;

			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}
			if (!params.filters) params.filters = {};


			let page = this.$route.params.page;
			// page = 'completed';
			
			this.page = page;

			if (page == 'completed') {
				if (!params.sortField) {
					params.sortField = "id";
					params.sortOrder = -1;
				}
				params.filters['status'] = { value: "pending", matchMode: "!=" };

			}
			if (page == 'review') {
				if (!params.sortField) {
					params.sortField = "id";
					params.sortOrder = 1;
				}
				params.filters['status'] = { value: "review", matchMode: "=" };

			}

			if (page == 'pending') {
				if (!params.sortField) {
					params.sortField = "urgent";
					params.sortOrder = 1;
				}
				params.filters['status'] = { value: "pending", matchMode: "=" };
			}
			
			if (page == 'specific') {
				if (!params.sortField) {
					params.sortField = "id";
					params.sortOrder = -1;
				}
				params.filters['assign_type'] = { value: "specific", matchMode: "=" };
			}
			this.radiologist = await this.get("radiologist/load-radiologist");


			params.joins = [
				{
					"fTable": "xray_reports",
					"fKey": "report_id",
					"fField": ["center_id", "patient_name", "patient_age", "patient_gender", "ref_doctor_name", "test_id", "test_date", "history", "reg_no", "mobile_no", "assign_doc_id", "assign_doc_ids", "assign_type", "images", "review_audio_file", "report", "status", "urgent", "review_notes", "review_done", "notes", "accepted_at", "created_at", "reported_at"]
				},
				{
					"fTable": ["xray_reports", "radiologists"],
					"fKey": "assign_doc_id",
					"fField": [["name", "assign_doc_name"]]
				},
				{
					"fTable": ["xray_reports", "centers"],
					"fKey": "center_id",
					"fField": [["code", "center_code"]]
				},
				{
					"fTable": ["xray_reports", "tests"],
					"fKey": "test_id",
					"fField": [["name", "test_name"]]
				},
			]


			this.post("radiologist/load-pivot_xray_report_doctor", params).then(res => {
				this.loading = false;
				if (!res.success) return;

				this.totalRecords = res.totalRecords;
				this.tableRecords = res.rows;

				for(let row of this.tableRecords){
					if(row.review_audio_file){
						row.reviewAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + row.review_audio_file;
					}
				}

				if (this.timerWorking) return;
				this.timerWorking = true;

				let w = () => {
					if (document.body.contains(this.$refs.container)) {
						for (let row of this.tableRecords) {
							row.accepted = row.assign_doc_id != 0;
							if (row.accepted_at) {
								let t1 = new Date(row.accepted_at);
								t1.setTime(t1.getTime() + 10 * 60 * 1000);
								let t2 = new Date();
								let td = t1.getTime() - t2.getTime();
								td = td / 1000;
								if (td < 0) td = 0;
								let m = String(Math.floor(td / 60));
								if (m.length == 1) m = '0' + m;
								let s = String(Math.floor(td % 60));
								if (s.length == 1) s = '0' + s;

								row.timer = '00:' + m + ':' + s;
							}

							let created_at = new Date(row.created_at).getTime();
							let now = new Date().getTime();

							if( row.status == 'pending' && (now - created_at) > 30*60*1000 ){
								row.urgent = 'y';
							}
						}
						setTimeout(w, 1000);
					}
				}
				w();
			});
		},
		
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		},
		async togglePresent() {
			await this.post('radiologist/toggle-present', {
				isPresent: this.isPresent
			})
		},
		async deletePivotReport(row) {
			await this.post('radiologist/delete-pivot-report', {
				id: row.id
			});
			this.imageViewerModal.hide();
			this.loadLazyData();
		}
	}
}
</script>
<style scoped>

</style>




